import React, { ReactNode } from "react"
import CityHomepage from "../../element/Svg/CityHomepage";

import "./styles.scss";

type Props = {
    mode?: string;
    left: ReactNode;
    right: ReactNode;
    skyline?: boolean;
}

const Split = ({
    mode,
    left,
    right,
    skyline,
}: Props) => {
    return (
        <section className={`section section-main section-split ${!!mode ? mode : ''}`}>
            <div className="section_inner">
                {!!skyline &&
                    <CityHomepage />
                }
                <div className="section__left split">
                    <div className="split_inner">
                        {left}
                    </div>
                </div>
                <div className="section__right split">
                    <div className="split_inner">
                        {right}
                    </div>
                </div>
            </div>
        </section >
    );
};


export default Split